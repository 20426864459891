// src/UploaderLogin.tsx
import React, { useState } from 'react';
import { Box, Heading, Text, Input, Stack, Button } from '@chakra-ui/react'; // Adjust Chakra UI imports as needed
import { useMutation } from 'react-query';
import axios from 'axios';
import { BASE_URL } from './env';

interface ApiResponse {
  data: {
    response: {
      user: {
        id: number;
        email: string;
        type: string;
        createdAt: string;
        updatedAt: string;
      };
      token: string;
    };
  };
}

const loginUser = async (email: string, password: string) => {
  try {
    const response = await axios.post<ApiResponse>(`${BASE_URL}/uploader/login`, {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    console.log("Invalid credentials")
  }
};

const UploaderLogin: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const mutation = useMutation((credentials: { email: string; password: string }) =>
    loginUser(credentials.email, credentials.password)
  );

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate({ email, password });
  };

  if (mutation.isSuccess) {
    // Store the token in local storage
    localStorage.setItem('uploaderToken', mutation.data?.data.response.token as string );
    // Redirect to /uploader
    window.location.href = '/uploader';
  }

  return (
    <Box textAlign="center" fontSize="xl">
      <Heading mt={4} color="primary">Uploader Login</Heading>
      <Text mt={4} color="primary">Enter your credentials to log in as an uploader:</Text>
      <form onSubmit={handleFormSubmit}>
        <Stack spacing={3} mt={4} align="center">
          <Input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" colorScheme="teal" isLoading={mutation.isLoading}>
            Log In
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default UploaderLogin;
