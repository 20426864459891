import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: '#4CAF50', // Set your primary color
    // Add more colors as needed
  },
  fonts: {
    body: 'Arial, sans-serif', // Set your default font
    heading: 'Georgia, serif', // Set your heading font
  },
  components: {
    // Customize Chakra UI components here
  },
});

export default theme;