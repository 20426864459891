// src/UploaderComponent.tsx
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import axios from 'axios';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import Joi from 'joi';
import moment from 'moment';
import { BASE_URL, departmentOptions } from './env';
import { capitalize_first_letter } from './utils';

interface Exam {
  id: number;
  period: string;
  department: string;
  from: string;
  to: string;
  venue: string;
  course_code: string;
  course_title: string;
  createdAt: string;
  updatedAt: string;
}

interface ApiResponse {
  message: string;
  data: {
    exams: Exam[];
  };
}

console.log( moment("2024-01-19T11:30:00.000Z").hours())

const fetchExams = async (token: string) => {
  const response = await axios.get<ApiResponse>(`${BASE_URL}/exam/uploader`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data.exams;
};

const createExamValidator = Joi.object({
  period: Joi.string().valid('mid_day', 'morning', 'afternoon').required(),
  department: Joi.string().required(),
  from: Joi.string().isoDate().required(),
  to: Joi.string(),
  venue: Joi.string().required(),
  course_code: Joi.string().allow(''),
  course_title: Joi.string().required(),
});

const UploaderComponent: React.FC = () => {
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    period: '',
    department: '',
    from: '',
    to: '',
    venue: '',
    // course_code: '',
    course_title: '',
  });
  const [validationError, setValidationError] = useState<string | null>(null);
  const [editExamId, setEditExamId] = useState<number | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('uploaderToken');
    if (!token) {
      window.location.replace('/uploader-login');
    }
  }, [location]);

  const { data: exams, isLoading, isError, refetch } = useQuery('exams', () => fetchExams(localStorage.getItem('uploaderToken') || ''));

  const createExamMutation = useMutation(
    (examData: Exam) =>
      axios.post(`${BASE_URL}/exam`, examData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('uploaderToken')}`,
        },
      }),
    {
      onSuccess: () => {
        refetch()
        fetchExams(localStorage.getItem('uploaderToken') || '');
        setModalOpen(false);
        setFormData({
          period: '',
          department: '',
          from: '',
          to: '',
          venue: '',
          // course_code: '',
          course_title: '',
        });
      },
    }
  );

  const deleteExamMutation = useMutation(
    (examId: number) =>
      axios.delete(`${BASE_URL}/exam?id=${examId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('uploaderToken')}`,
        },
      }),
    {
      onSuccess: () => {
        refetch();
        fetchExams(localStorage.getItem('uploaderToken') || '');
      },
    }
  );

  const editExamMutation = useMutation(
    (examData: Exam) =>
      axios.patch(`${BASE_URL}/exam`, {...examData, id: editExamId as   number}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('uploaderToken')}`,
        },
      }),
    {
      onSuccess: () => {
        refetch()
        fetchExams(localStorage.getItem('uploaderToken') || '');
        setModalOpen(false);
        setFormData({
          period: '',
          department: '',
          from: '',
          to: '',
          venue: '',
          // course_code: '',
          course_title: '',
        });
        setEditExamId(null);
      },
    }
  );

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditExamId(null);
  };

  const handleEdit = (examId: number) => {
    const examToEdit = exams!.find((exam) => exam.id === examId);
    if (examToEdit) {
      const from_time = new Date(examToEdit.from).toISOString();

      const to_time = moment( moment(examToEdit.from).format("YYYY-MM-DD") + ' ' + formData.to,  "YYYY-MM-DD HH:mm").toISOString()

      setFormData({
        period: examToEdit.period,
        department: examToEdit.department,
        from: from_time,
        to: to_time,
        venue: examToEdit.venue,
        // course_code: examToEdit.course_code,
        course_title: examToEdit.course_title,
      });
      setEditExamId(examId);
      handleOpenModal();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const { error } = createExamValidator.validate(formData, { abortEarly: false });

      const from_time = moment(formData.from).toISOString()

      const to_time = moment( moment(formData.from).format("YYYY-MM-DD") + ' ' + formData.to,  "YYYY-MM-DD HH:mm").toISOString()

      if (error) {
        setValidationError(error.details.map((detail) => detail.message).join(', '));
        return;
      }

      setValidationError(null);

      if (editExamId) {
        await editExamMutation.mutateAsync({
          ...formData,
          from: from_time,
          to: to_time,
        } as any);
      } else {
        await createExamMutation.mutateAsync({
          ...formData,
          from: from_time,
          to: to_time,
        } as any);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };



  const handleDelete = (examId: number) => {
    if (window.confirm('Are you sure you want to delete this exam?')) {
      deleteExamMutation.mutate(examId);
    }
  };

  return (
    <Box>
      <h1>Uploader Component</h1>
      <Button onClick={handleOpenModal}>Create Exam</Button>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editExamId ? 'Edit Exam' : 'Create New Exam'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {/* ... (existing code) */}
              <Stack spacing={4}>
              <FormControl isInvalid={!!validationError} mb={4}>
                <FormLabel>Period</FormLabel>
                <Select name="period" value={formData.period} onChange={handleChange}>
                  <option value="">Select period</option>
                  <option value="mid_day">Mid Day</option>
                  <option value="morning">Morning</option>
                  <option value="afternoon">Afternoon</option>
                </Select>
              </FormControl>

              <FormControl isInvalid={!!validationError} mb={4}>
                <FormLabel>Department</FormLabel>
                <Select
            name="department" value={formData.department} onChange={handleChange}
          >
                   <option value="">Select Department   </option>
             {departmentOptions.map((option) => (
                
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
              </FormControl>

              <FormControl isInvalid={!!validationError} mb={4}>
                <FormLabel>From</FormLabel>
                <Input type="datetime-local" name="from" value={formData.from} onChange={handleChange} />
              </FormControl>

              <FormControl isInvalid={!!validationError} mb={4}>
                <FormLabel>To</FormLabel>
                <Input type="time" name="to" value={formData.to} onChange={handleChange} />
              </FormControl>

              <FormControl isInvalid={!!validationError} mb={4}>
                <FormLabel>Venue</FormLabel>
                <Input name="venue" value={formData.venue} onChange={handleChange} />
              </FormControl>

              {/* <FormControl isInvalid={!!validationError} mb={4}>
                <FormLabel>Course Code</FormLabel>
                <Input name="course_code" value={formData.course_code} onChange={handleChange} />
              </FormControl> */}

              <FormControl isInvalid={!!validationError} mb={4}>
                <FormLabel>Course Title</FormLabel>
                <Input name="course_title" value={formData.course_title} onChange={handleChange} />
              </FormControl>

              {validationError && <FormErrorMessage color="red.500">{validationError}</FormErrorMessage>}

        
            </Stack>

              <Button colorScheme="blue" onClick={handleSubmit}>
                {editExamId ? 'Update Exam' : 'Create Exam'}
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Exam ID</Th>
            <Th>Period</Th>
            <Th>Department</Th>
            <Th>From</Th>
            <Th>To</Th>
            <Th>Venue</Th>
            {/* <Th>Course Code</Th> */}
            <Th>Course Title</Th>
            <Th>Edit</Th>
            <Th>Delete</Th>
          </Tr>
        </Thead>
       {exams && <Tbody>
          {exams!.map((exam) => (
            <Tr key={exam.id}>
              <Td>{exam.id}</Td>
              <Td>{capitalize_first_letter(exam.period.replace("_", ""))}</Td>
              <Td>{exam.department}</Td>
              <Td>{moment(exam.from).format('YYYY-MM-DD HH:mm')}</Td>
              <Td>{moment(exam.to).format('YYYY-MM-DD HH:mm')}</Td>
              <Td>{exam.venue}</Td>
              {/* <Td>{exam.course_code}</Td> */}
              <Td>{exam.course_title}</Td>
              <Td>
                <Button onClick={() => handleEdit(exam.id)}>Edit</Button>
              </Td>
              <Td>
                  <Button onClick={() => handleDelete(exam.id)} colorScheme="red">
                    Delete
                  </Button>
                  </Td>
            </Tr>
          ))}
        </Tbody>}
      </Table>
    </Box>
  );
};

export default UploaderComponent;
