// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import SPVRoute from './SPVRoute';
import UploaderLogin from './UploaderLogin';
import UploaderComponent from './UploaderComponent';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/spv" element={<SPVRoute />} />
        <Route path="/" element={<Home />} />
        <Route path="/uploader-login" element={<UploaderLogin />} />
        <Route path="/uploader" element={<UploaderComponent />} /> {/* Add the new route */}
      </Routes>
    </Router>
  );
}

export default App;
