// src/SPV.tsx
import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Stack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';
import axios from 'axios';
import moment from 'moment';
import { BASE_URL, departmentOptions } from './env';

interface ExamData {
  id: number;
  period: string;
  department: string;
  from: string;
  to: string;
  venue: string;
  course_code: string;
  course_title: string;
  LecturerInvigilations: {
    id: number;
    InvigilationId: number;
    LecturerId: number;
    createdAt: string;
    updatedAt: string;
    Lecturer: {
      id: number;
      name: string;
      email: string;
      department: string;
      phone_number: string;
      type: string;
      createdAt: string;
      updatedAt: string;
    };
  }[];
}

interface ApiResponse {
  data: {
    exams: ExamData[];
  };
}

const fetchData = async (department: string) => {
  try {
    const response = await axios.post<ApiResponse>(`${BASE_URL}/spv`, {
      department,
    });

    return response.data;
  } catch (error) {
    throw new Error('Error fetching data');
  }
};

const SPV = () => {
  const [department, setDepartment] = useState('');

  const mutation = useMutation((department: string) => fetchData(department));

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate(department);
  };



  return (
    <Box textAlign="center" fontSize="xl">
      <Heading mt={4} color="primary">Supervisor Exam Details</Heading>
      <Text mt={4} color="primary">Enter the department to view exam details:</Text>
      <form onSubmit={handleFormSubmit}>
        <Stack spacing={3} mt={4} align="center">
          <Select
            placeholder="Select Department"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          >
             {departmentOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          <Button type="submit" colorScheme="teal">
            {mutation.isLoading ? 'Fetching...' : 'Fetch Exam Details'}
          </Button>
        </Stack>
      </form>

      {/* Display Exam Details Table */}
      <Box mt={8}>
        <Table variant="striped" colorScheme="teal" mt={4}>
          <Thead fontSize="12px">
            <Tr>
              <Th>Course Title</Th>
              <Th>Course Code</Th>
              <Th>Date</Th>
              <Th>Venue</Th>
              <Th>Lecturers</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="12px">
            {mutation.data?.data.exams.map((exam) => (
              <Tr key={exam.id}>
                <Td>{exam.course_title}</Td>
                <Td>{exam.course_code}</Td>
                <Td>{`${moment(exam.from).format('YYYY-MM-DD')}  ${moment(
                  exam.from
                ).format('HH:mm')} - ${moment(exam.to).format('HH:mm')}`}</Td>
                <Td>{exam.venue}</Td>
                <Td>
                  {exam.LecturerInvigilations.map((invigilation) => (
                    <div style={{ margin: ".5em 0"}} key={invigilation.id}>{invigilation.Lecturer.name.split(" ").slice(0, 3).join(" ")} 0{invigilation.Lecturer.phone_number}</div>
                  ))}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default SPV;
