// src/Home.tsx
import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Input,
  Stack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';
import axios from 'axios';
import moment from 'moment';
import { BASE_URL, departmentOptions } from './env';

interface ExamData {
  id: number;
  period: string;
  department: string;
  from: string;
  to: string;
  venue: string;
  course_code: string;
  course_title: string;
}

interface InvigilationData {
  id: number;
  InvigilationId: number;
  LecturerId: number;
  ExamId: number;
  createdAt: string;
  updatedAt: string;
  Exam: ExamData;
}

interface LecturerData {
  id: number;
  name: string;
  email: string;
  department: string;
  phone_number: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

interface ApiResponse {
  data: {
    invigilations: InvigilationData[];
    lecturer: LecturerData;
  };
}





const Home = () => {
  const [surname, setSurname] = useState('');
  const [department, setDepartment] = useState('');
  const [error, setError] = useState('');

  const fetchData = async (surname: string, department: string) => {
    try {
      const response = await axios.post<ApiResponse>(`${BASE_URL}/lecturers`, {
        name: surname,
        department,
      });
  
      if(!response.data.data.invigilations) setError("Invalid credentials")
  
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data');
    }
  };

  const mutation = useMutation((formData: { surname: string; department: string }) =>
    fetchData(formData.surname, formData.department)
  );

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate({ surname, department });
  };

  return (
    <Box textAlign="center" fontSize="xl">
      <Heading mt={4} color="primary">Exam Invigilation Schedules</Heading>
      <Text mt={4} color="primary">Enter your surname and department to view schedules:</Text>
      <form onSubmit={handleFormSubmit}>
        <Stack spacing={3} mt={4} align="center">
          <Input
          required
            type="text"
            placeholder="Invigilator's Surname"
            _placeholder={{ color: "black"}}
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          <Select
          required
            placeholder="Select Department"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          >
            {departmentOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          <Button type="submit" colorScheme="teal">
            {mutation.isLoading ? 'Submitting...' : 'Submit'}
          </Button>
        </Stack>
      </form>

      {/* Display Exam Schedules Table */}
      <Box mt={8}>

        { mutation.data?.data.lecturer ? <>
        <Text>Lecturer Details:</Text>
        <Text>Name : <strong> {mutation.data?.data.lecturer.name}</strong></Text>
        <Text>Department : <strong> {mutation.data?.data.lecturer.department}</strong></Text>
        </> : <Text color={"red"}>{error}</Text>}
        
        
        <Table variant="striped" colorScheme="teal" mt={4}>
          <Thead fontSize="12px">
            <Tr>
              <Th>Course Title</Th>
              <Th>Course Code</Th>
              <Th>Department</Th>
              <Th>Date</Th>
              <Th>Venue</Th>
            </Tr>
          </Thead>
         { mutation.data?.data?.invigilations ? <Tbody fontSize="12px">
            {mutation.data?.data.invigilations.map((invigilation) => (
              <Tr key={invigilation.id}>
                <Td>{invigilation.Exam.course_title}</Td>
                <Td>{invigilation.Exam.course_code}</Td>
                <Td>{invigilation.Exam.department}</Td>
                <Td>{`${moment(invigilation.Exam.from).format('YYYY-MM-DD')}  ${moment(
                  invigilation.Exam.from
                ).format('HH:mm')} - ${moment(invigilation.Exam.to).format('HH:mm')}`}</Td>
                <Td>{invigilation.Exam.venue}</Td>
              </Tr>
            ))}
          </Tbody> : <Text color={"red"}> </Text> }
        </Table>
      </Box>
    </Box>
  );
};

export default Home;
